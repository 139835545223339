import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";
import { Grid, Link, TextField, Typography } from "@material-ui/core";
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons";
import { useStepStyles } from "./StepStyles";
import {
  createCollectionUpdateMethod,
  addObjectToCollection,
  removeObjectFromCollection,
} from "../utils/utils";
import {
  PreDocTrainingGrantTypes,
  PostDocTrainingGrantTypes,
  TrainingGrantSources,
  stepName,
} from "../constants";

const getGrantYears = (numYears) => {
  let grantYears = {};
  [...Array(numYears).keys()].forEach(
    (x) => (grantYears[`TY${x + 1}`] = `Training Year ${x + 1}`)
  );
  return grantYears;
};

const maxTrainingGrants = 20;

const isGrantTypeNih = (type) => type.indexOf("NIH_HHS") > -1;

export const TrainingSupportStep = ({ traineeData, callbacks }) => {
  const classes = useStepStyles();
  const { t } = useTranslation();
  const { register, control, errors } = useFormContext();

  const [trainingGrants, setTrainingGrants] = React.useState(
    Array.isArray(traineeData.trainingGrants)
      ? traineeData.trainingGrants.slice(0, maxTrainingGrants)
      : [{ year: null }]
  );

  const trainingYears = getGrantYears(20);
  const trainingTypes =
    traineeData.traineeType === "PRE_DOC"
      ? PreDocTrainingGrantTypes
      : PostDocTrainingGrantTypes;

  /**
   * Processes form data before submitting.
   *
   * @param {object} formData the values from the form
   * @returns {object} the processed form data
   */
  callbacks.beforeSubmit = (formData) => {
    //console.log("TrainingSupportStep - beforeSubmit");
    return {
      stepName: stepName.ABOUT_TRAINING_SUPPORT,
      trainingGrants: formData.trainingGrants,
    };
  };

  const formatSupport = (oldVal, newVal) => {
    let formatted = "";
    // if appart from whitespace, old and new are the same.
    // (this allows the user to delete whitespace chars)
    if (oldVal && oldVal.trim() === newVal) {
      formatted = newVal;
    } else {
      formatted = newVal.toUpperCase();
    }
    return formatted;
  };

  // React Hook Form onChange callback
  const updateTrainingGrant =
    (idx, keyName) =>
    (...args) => {
      // Support two method signatures:
      // - React-hook-form callback: ([event, value])
      // - Material UI callback: (event, value)
      let [event, value] = Array.isArray(args[0]) ? args[0] : args;
      value = value || event.currentTarget.value;

      if (keyName === "support") {
        value = formatSupport(trainingGrants[idx][keyName], value);
      }
      return createCollectionUpdateMethod(
        idx,
        keyName,
        trainingGrants,
        setTrainingGrants
      )([event, value]);
    };

  const addTrainingGrant = () =>
    addObjectToCollection(
      { year: `TY${trainingGrants.length + 1}`, type: null },
      trainingGrants,
      setTrainingGrants,
      async () => {
        control.register(`trainingGrants[${trainingGrants.length}].type`, {
          required: true,
        });
        await control.triggerValidation();
      }
    );

  const removeTrainingGrant = (idx) =>
    removeObjectFromCollection(idx, setTrainingGrants, async () => {
      control.unregister([
        `trainingGrants[${idx}].year`,
        `trainingGrants[${idx}].type`,
        `trainingGrants[${idx}].source`,
        `trainingGrants[${idx}].support`,
      ]);
      await control.triggerValidation();
    });
  //console.log("errors", errors);

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="flex-start"
      direction="column"
      classes={{ container: classes.containerWrapper }}
      spacing={2}
    >
      <input
        type="hidden"
        name="page"
        value="trainingSupportStep"
        ref={register}
      />
      <Grid item classes={{ item: classes.stepItem }}>
        <Typography variant={"h2"} classes={{ root: classes.stepTitle }}>
          {t("steps.trainingSupport.h2Title")}
        </Typography>
      </Grid>
      <Grid item classes={{ item: classes.stepItem }}>
        <Grid container direction="column" alignItems="flex-start" spacing={2}>
          {trainingGrants.map((field, idx) => {
            return (
              <React.Fragment key={`${field}-${idx}`}>
                <Grid item classes={{ item: classes.stepItem }}>
                  <Typography
                    variant={"h4"}
                    classes={{ root: classes.stepSubtitle }}
                  >
                    {trainingYears[field.year]}
                  </Typography>
                  <input
                    type="hidden"
                    name={`trainingGrants[${idx}].year`}
                    value={field.year || `TY1`}
                    ref={register}
                  />
                </Grid>
                <Grid item classes={{ item: classes.stepItem }}>
                  <TextField
                    select
                    name={`trainingGrants[${idx}].type`}
                    label={t("steps.trainingSupport.trainingType")}
                    defaultValue={field.type || ""}
                    onChange={updateTrainingGrant(idx, "type")}
                    SelectProps={{
                      native: true,
                    }}
                    inputRef={register({
                      validate: (value) => !!value.trim(),
                    })}
                    required
                    error={!!errors?.trainingGrants?.[idx]?.type}
                    variant="outlined"
                    margin="normal"
                  >
                    <option
                      disabled
                      hidden
                      style={{ display: "none" }}
                      value=""
                    ></option>
                    {Object.keys(trainingTypes).map((option) => (
                      <option key={option} value={option}>
                        {t(trainingTypes[option])}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                {field?.type && (
                  <Grid item classes={{ item: classes.stepItem }}>
                    {field?.type && !isGrantTypeNih(field.type) ? (
                      <TextField
                        select
                        name={`trainingGrants[${idx}].source`}
                        label={t("steps.trainingSupport.source")}
                        defaultValue={field.source || ""}
                        onChange={updateTrainingGrant(idx, "source")}
                        SelectProps={{
                          native: true,
                        }}
                        inputRef={register({
                          validate: (value) => !!value.trim(),
                        })}
                        required
                        error={!!errors?.trainingGrants?.[idx]?.source}
                        variant="outlined"
                        margin="normal"
                      >
                        <option
                          disabled
                          hidden
                          style={{ display: "none" }}
                          value=""
                        ></option>
                        {Object.keys(TrainingGrantSources).map((option) => (
                          <option key={option} value={option}>
                            {t(TrainingGrantSources[option])}
                          </option>
                        ))}
                      </TextField>
                    ) : (
                      <Controller
                        name={`trainingGrants[${idx}].support`}
                        control={control}
                        defaultValue={field.support || ""}
                        rules={{
                          required: true,
                          validate: (value) => value.length > 0,
                        }}
                        onChange={updateTrainingGrant(idx, "support")}
                        as={
                          <TextField
                            required
                            error={!!errors?.trainingGrants?.[idx]?.support}
                            label={t("steps.trainingSupport.support")}
                            variant="outlined"
                            helperText={t(
                              "steps.trainingSupport.supportHelpText"
                            )}
                            inputProps={{ maxLength: 30 }}
                          />
                        }
                      />
                    )}
                  </Grid>
                )}
                <Grid item classes={{ item: classes.stepItem }}>
                  <Grid container direction="row" justify="flex-end">
                    {trainingGrants.length < maxTrainingGrants &&
                      idx === trainingGrants.length - 1 && (
                        <Link
                          classes={{ root: classes.degreeLink }}
                          component="button"
                          type="button"
                          onClick={addTrainingGrant}
                        >
                          <AddIcon /> {t("common.add")}{" "}
                          {t("steps.trainingSupport.trainingYear")}
                        </Link>
                      )}
                    {trainingGrants.length > 1 &&
                      idx === trainingGrants.length - 1 && (
                        <Link
                          classes={{ root: classes.degreeLink }}
                          component="button"
                          type="button"
                          onClick={removeTrainingGrant(idx)}
                        >
                          <RemoveIcon /> {t("common.remove")}{" "}
                          {t("steps.trainingSupport.trainingYear")}
                        </Link>
                      )}
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};
