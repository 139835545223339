import React from "react";
import { usePortalStyles } from "./PortalStyles";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { CheckCircle as CheckCircleIcon } from "@material-ui/icons";

export const SubmittedStep = () => {
  const classes = usePortalStyles();
  const { t } = useTranslation();

  //console.log("Rendering Submitted step");

  return (
    <Grid item xs={10} sm={8} md={8} lg={5} xl={4}>
      <Grid container justify="center" direction="column">
        <Grid item classes={{ item: classes.itemWrapper }}>
          <Typography
            variant={"h2"}
            classes={{ root: classes.headerTitleRoot }}
          >
            {t("steps.submitted.h2Title")}
          </Typography>
        </Grid>
        <Grid item classes={{ item: classes.itemWrapper }}>
          <Typography
            variant={"h2"}
            classes={{ root: classes.headerTitleRoot }}
          >
            <CheckCircleIcon
              fontSize="large"
              classes={{ root: classes.checkIcon }}
            />
          </Typography>
        </Grid>
        <Grid item classes={{ item: classes.itemWrapper }}>
          <Typography
            variant={"h3"}
            classes={{ root: classes.headerSubTitle1 }}
          >
            {t("steps.submitted.successfullySubmitted")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
