import React from "react";
import i18n from "../i18n";
import { InvitationNotFoundUrl } from "../constants";

export default class RedirectPage extends React.Component {
  componentDidMount() {
    window.location.replace(`${i18n.t(InvitationNotFoundUrl)}`);
  }
  render() {
    return "";
  }
}
