// src/services/traineeService.js
// Trainee service for the "trainees/" endpoint, handling trainee state management and API errors.
// Exports a useTrainee custom hook for accessing trainee data, such as displayName and permissions.

import React from "react";
import axios from "axios";
import { useAlertContext } from "@stanford-tds/as-components";
import { formatComponentActivity } from "../utils/utils";

// Default traneeData values
const defaultValues = {
  firstName: "",
  lastName: "",
  middleName: "",
  traineeType: "PRE_DOC",
  trainingStartDate: null,
  trainingEndDate: null,
  researchTopic: "",
  __loaded: false,
};

/**
 * Sorts, and formats properties in the trainingData object.
 * @private
 * @param {object} traineeData
 * @returns {object} the updated training data object.
 */
const prepareTraineeData = (traineeData) => {
  if (Array.isArray(traineeData.trainingGrants)) {
    traineeData.trainingGrants.sort(compareTrainingGrantYears);

    // Format bad component & activity data from API
    traineeData.trainingGrants = traineeData.trainingGrants.map((obj) => {
      if (obj.support) {
        return {
          ...{ support: formatComponentActivity(obj.support) },
          ...obj,
        };
      } else {
        return obj;
      }
    });
  }

  if (Array.isArray(traineeData.employments)) {
    let initialPosition = extractFirstPosition(
      traineeData.employments,
      "INITIAL_POSITION"
    ) || { position: "" };
    let currentPosition = extractFirstPosition(
      traineeData.employments,
      "CURRENT_POSITION"
    ) || { position: "" };
    traineeData.employments = [initialPosition, currentPosition];
  }

  return traineeData;
};

/**
 * Sorts grant years ascending
 */
const compareTrainingGrantYears = (a, b) => {
  return (
    parseInt(a.year.replace(/[a-zA-Z]+/gi, ""), 10) -
    parseInt(b.year.replace(/[a-zA-Z]+/gi, ""), 10)
  );
};

/**
 * Extracts the first object with a positionType property that matches the positionType argument.
 * @private
 * @param {array} employmentsArr the collection of objects to search.
 * @param {string} postionType the value to look for.
 * @returns {object} the employment object to return.
 */
const extractFirstPosition = (employmentsArr, postionType) => {
  return employmentsArr.find((entry) => entry.positionType === postionType);
};

export const TraineeContext = React.createContext(defaultValues);

// Context provider
export const TraineeProvider = ({ userToken, children }) => {
  const [traineeData, setTraineeData] = React.useState(defaultValues);
  const { setAlert } = useAlertContext();

  React.useEffect(() => {
    (async () => {
      if (userToken && userToken.length > 0) {
        let response;
        try {
          response = await axios({
            url: "/trainees/" + userToken,
          });
          //console.log("API reponse obj:", response);
        } catch (error) {
          // Rules for handling API errors in all services:
          // 1. For all errors, an error message must be displayed to the user. The following rules cover what error message
          //    to display and where to display the error message.
          // 2. In the error object, error.message contains a default error message that's already set for the current error.
          //    If there is a story requirement to display a custom error message for the current error, then the service must
          //    replace the default error message with the custom error message.
          // 3. To determine if the current error is one the story requires to be custom, the service should inspect the combination
          //    of error.status and error.code in the error object.
          // 4. If the current error is required to be custom, the service should lookup the custom error message in translation.json
          //    and if needed format it with information from error.response.data in the error object.
          // 5. If the default error message has been replaced by a custom error message, the service must set the custom error
          //    message to display in either the ASAlert component or a form field error, depending on the story requirements.
          // 6. If the default error message has not been replaced, the service must set the default error message to display
          //    in the ASAlert component.

          // IMPORTANT: This default setAlert must be called if no custom error message has been set
          if ([401, 404].indexOf(error.status) > -1) {
            response = { error: error.status };
          } else {
            setAlert("error", error.message);
          }
        }

        if (response?.data) {
          setTraineeData(prepareTraineeData(response.data));
        } else {
          setTraineeData(response);
        }
      } else {
        console.log("No UUID identified in the URL path.");
      }
    })();
  }, [setAlert, userToken]);

  return (
    <TraineeContext.Provider value={{ traineeData, setTraineeData, userToken }}>
      {children}
    </TraineeContext.Provider>
  );
};

// Custom hook
export const useTrainee = () => {
  return React.useContext(TraineeContext);
};

export const useTraineeApi = () => {
  /**
   * Performs an HTTP put request to save traneeData.
   * @param {string} uuid the user token.
   * @param {object} traineeData the data to save.
   * @returns a promise to save the data.
   */
  const saveTrainee = (uuid, traineeData) => {
    const saveTraineeRequest = {
      universityId: traineeData.universityId,
      uuid: traineeData.uuid,
      firstName: traineeData.firstName,
      lastName: traineeData.lastName,
      middleName: traineeData.middleName,
      email: traineeData.email,
      researchTopic: traineeData.researchTopic,
      terminalDegrees: traineeData.terminalDegrees,
      faculty: traineeData.faculty,
      resultingDegrees: traineeData.resultingDegrees,
      traineeSubmitDate: traineeData.traineeSubmitDate,
      traineeSubmitStatus: traineeData.traineeSubmitStatus,
      traineeType: traineeData.traineeType,
      trainingStartDate: traineeData.trainingStartDate,
      trainingStartDateYear: traineeData.trainingStartDateYear,
      trainingEndDate: traineeData.trainingEndDate,
      trainingEndDateYear: traineeData.trainingEndDateYear,
      trainingGrants: traineeData.trainingGrants,
      stepName: traineeData.stepName,
    };
    if (Array.isArray(traineeData.employments)) {
      saveTraineeRequest.employments = traineeData.employments.filter(
        (entry) => entry.position
      );
    }
    if (Array.isArray(traineeData.subsequentGrants)) {
      saveTraineeRequest.subsequentGrants = traineeData.subsequentGrants.filter(
        (entry) => entry.type
      );
    }

    const request = {
      method: "put",
      url: `trainees/${uuid}`,
      data: saveTraineeRequest,
    };
    return axios(request);
  };

  return { saveTrainee };
};
