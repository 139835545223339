import React from "react";
import { useTranslation } from "react-i18next";
import RedirectPage from "./RedirectPage";

export const ErrorPage = ({ httpErrorCode }) => {
  const { t } = useTranslation();

  let errorText = t("common.error.invitationError");

  return (
    <>
      {httpErrorCode === 404 ? (
        <RedirectPage />
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: errorText,
          }}
        ></div>
      )}
    </>
  );
};
