import React from "react";
import { useForm, FormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usePortalStyles } from "./PortalStyles";
import { Button, Grid, CircularProgress } from "@material-ui/core";
import { useAsync } from "../utils/useAsync";
import { useTraineeApi } from "../services/traineeService";
import { ConfirmDialog } from "./ConfirmDialog";
import { useAlertContext } from "@stanford-tds/as-components";

/**
 * Wraps a Portal Step in a form with Back and Next buttons, includes
 * callbacks that handle back, next & save functionalities.
 *
 * Passes an optional beforeSubmit method to the child component to
 * to parse data before submiting.
 */
export const SteppedForm = ({
  setActiveStep,
  isFirstStep,
  isLastStep,
  setTraineeData,
  userToken,
  ...props
}) => {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const { t } = useTranslation();
  const classes = usePortalStyles();
  const { saveTrainee } = useTraineeApi();
  const { setAlert, clearAlert } = useAlertContext();
  const {
    register,
    control,
    handleSubmit,
    errors,
    clearError: clearFormError,
    formState,
    reset: resetForm,
  } = useForm({
    mode: "all",
  });

  // Wrap the PUT request to save traineeData in the 'useAsync' hook
  // to return status, errors, etc...
  const {
    execute: saveTraineeData,
    status: saveStatus,
    error: saveError,
    reset: resetSaveStatus,
  } = useAsync(
    // NOTE: The following is the saveTraineeData method definition
    // The useAsync.execute method does not allow any arguments.
    // I'm setting a property on the 'proxyObj' object to pass the traineeData by reference to the target method.
    () => saveTrainee(userToken, proxyObj.saveData),
    false
  );

  let proxyObj = { saveData: null };

  const { dirty: formIsDirty } = formState;

  // NOTE: formState.isValid is too buggy to use.  This value is a workaround until the library is upgraded.
  const formIsValid =
    Object.keys(errors).length === 0 && errors.constructor === Object;

  /*console.log("SteppedForm - errors", errors);
  console.log("formState.dirty", formIsDirty, formState.dirtyFields);
  console.log(
    "' - next button disabled'",
    formIsValid,
    saveStatus === "pending",
    saveStatus
  );*/

  const goForward = () => {
    resetForm(undefined, {
      dirtyFields: false, // dirtyFields will be reset
      errors: true, // anything with true will not be reset
      dirty: false,
      isSubmitted: true,
      touched: true,
      isValid: true,
      submitCount: true,
    });
    resetSaveStatus();
    clearAlert();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const goBack = () => {
    resetForm(undefined, {
      dirtyFields: false, // dirtyFields will be reset
      errors: true, // anything with true will not be reset
      dirty: false,
      isSubmitted: true,
      touched: true,
      isValid: true,
      submitCount: true,
    });
    clearFormError();
    clearAlert();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Contains callback methods which get defined in child 'xxxStep' components.
  const callbacks = {};

  /**
   * Processes & saves the submitted form data.
   * @param {object} formData the values from the form
   */
  const submit = (formData) => {
    //console.log("onSubmit()", formData);

    if (typeof callbacks.beforeSubmit === "function") {
      formData = callbacks.beforeSubmit(formData);
    }

    setTraineeData((prevState) => {
      const newState = {
        ...prevState,
        ...formData,
      };
      // Submit the updated traineeData to the API
      proxyObj.saveData = newState;
      saveTraineeData();
      return newState;
    });
  };

  /**
   * Callback for back button
   */
  const handleBack = () => {
    if (formIsDirty) {
      setShowConfirm(true);
    } else {
      goBack();
    }
  };

  /**
   * Callback for confirm button
   */
  const handleConfirm = () => {
    goBack();
  };

  // Inject additions to children props
  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { callbacks, ...props });
    }
    return child;
  });

  // After render...
  React.useEffect(() => {
    // When submit succeeds, move to the next step
    if (saveStatus === "success") {
      //console.log("saveStatus === success");
      goForward();
    }

    // Display errors via the alert component
    if (saveStatus === "error") {
      setAlert(
        "error",
        saveError.message ? saveError.message : t("common.error.submitError")
      );
      resetSaveStatus();
    }
  });

  return (
    <FormContext register={register} control={control} errors={errors}>
      <form onSubmit={handleSubmit(submit)}>
        {childrenWithProps}

        <Grid item classes={{ item: classes.buttonsWrapper }}>
          <Button
            disabled={isFirstStep}
            onClick={handleBack}
            className={classes.backButton}
          >
            {t("common.back")}
          </Button>
          <Button
            type="submit"
            disabled={!formIsValid || saveStatus === "pending"}
            variant="contained"
            color="primary"
            className={classes.nextButton}
          >
            {saveStatus === "pending" && (
              <>
                <CircularProgress
                  className={classes.circularProgress}
                  size={20}
                />{" "}
              </>
            )}
            {isLastStep ? t("common.finish") : t("common.next")}
          </Button>
          <ConfirmDialog
            open={showConfirm}
            setOpen={setShowConfirm}
            onConfirm={handleConfirm}
            className={classes.confirmDialog}
          >
            {t("confirmBackDialog.body")}
          </ConfirmDialog>
        </Grid>
      </form>
    </FormContext>
  );
};
