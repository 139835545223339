/**
 * Parses any URL into it's component parts.
 *
 * @param {string} url to be parsed
 * @returns {object} map of URL components
 */
export const parseUrl = (url) => {
  var tmpLink = document.createElement("a");
  tmpLink.href = url;

  let {
    protocol,
    host,
    hostname,
    port,
    pathname,
    hash,
    search,
    origin,
  } = tmpLink;

  // IE does not prefix with a slash
  if (pathname && pathname[0] !== "/") {
    pathname = "/" + pathname;
  }
  return { protocol, host, hostname, port, pathname, hash, search, origin };
};

/**
 * Prevents a function from being called too often, waits until a timer elapses to call it again.
 * If you need to cancel a scheduled debounce, you can call .cancel() on the debounced function.
 * @param {function} func the function to call.
 * @param {number} wait number of miliseconds to wait before calling the function.
 * @returns {function} the "debounced wrapped" function.
 */
export const debounce = function (func, wait) {
  let timeout, result;

  const later = (...args) => {
    timeout = null;
    if (args) result = func(...args);
  };

  const debounced = (...args) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      return later(...args);
    }, wait);
    return result;
  };

  debounced.cancel = () => {
    clearTimeout(timeout);
    timeout = null;
  };

  return debounced;
};

/**
 * Returns a decending array of years starting from the current year.
 * @param {integer} numYears
 * @returns {array}
 */
export const getYearsDecending = (numYears) =>
  [...Array(numYears).keys()].map((x) =>
    parseInt(new Date().getFullYear() - x + 0, 10)
  );

/**
 * Formats Component & Activity notation according to the following:
 * - 2 letters followed by space, followed by 3 characters (letters or numbers). Example: HL T32.
 * @param {string} val unformatted string
 * @returns {string} formatted string
 */
export const formatComponentActivity = (val) => {
  let formatted = "";
  val = val
    .trim()
    .replace(/[\W_]+/gi, "")
    .toUpperCase();
  val = val.substring(0, 2).replace(/[^a-zA-Z]/gi, "") + val.substring(2, 5);
  if (val.length < 2) {
    formatted = val;
  } else {
    formatted = `${val.substring(0, 2)} ${val.substring(2, 5)}`;
  }
  return formatted;
};

/**
 * Returns a callback function that updates a property of an object (referenced by index) in a collection.
 * The retured callback returns the passed property value.
 * NOTE: React-hook-form onChange callback sends the "event and value" params as an array.
 * @param {number} idx the index of the object to update.
 * @param {string} keyName the name of the property to update.
 * @param {array} collection the collection to interate through.
 * @param {function} setCollection the React set-state method to update the collection.
 * @returns {function} the update callback function
 */
export const createCollectionUpdateMethod = (
  idx,
  keyName,
  collection,
  setCollectionFn
) => (...args) => {
  // Support two method signatures:
  // - React-hook-form callback: ([event, value])
  // - Material UI callback: (event, value)
  let [event, value] = Array.isArray(args[0]) ? args[0] : args;
  value = value || event.target.value;

  // Update the object in the collection
  const newCollection = collection.map((entry, fidx) => {
    if (idx !== fidx) return entry;
    return {
      ...entry,
      [keyName]: value,
    };
  });
  setCollectionFn(newCollection);
  return value;
};

/**
 * Adds an object to a collection.
 * @param {object} newObject
 * @param {array} collection
 * @param {function} setCollectionFn
 */
export const addObjectToCollection = (
  newObject,
  collection,
  setCollectionFn,
  afterFn
) => {
  setCollectionFn(collection.concat([newObject]));
  if (typeof afterFn === "function") afterFn();
};

/**
 * Removes an object from a collection.
 * @param {number} idx the index of the object to remove.
 * @param {function} setCollectionFn the React set-state method to update the collection.
 * @returns {function} the removal function
 */
export const removeObjectFromCollection = (
  idx,
  setCollectionFn,
  afterFn
) => () => {
  setCollectionFn((prevState) => {
    const newCollection = prevState.filter((s, sidx) => {
      return idx !== sidx;
    });
    return newCollection;
  });
  if (typeof afterFn === "function") afterFn();
};

export const isString = (val) =>
  typeof val === "string" || val instanceof String;
