import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAsync } from "../../utils/useAsync";
import { useFacultyApi } from "../../services/facultyService";
import { debounce } from "../../utils/utils";
//import { useAlertContext } from "@stanford-tds/as-components";

/**
 * Renders a Faculty Autosuggest component.
 *
 * @component
 * @param {object} props
 * @param {string} props.label The label content.
 * @param {boolean} props.isValid If true, the label will be displayed in an error state.
 * @param {number} props.maxResults The maximum number of search results to display in the popup.
 * @param {number} props.minChars The minimum character required to start a search.
 * @param {number} props.debouncePeriod Debounce millisecond delay before starting a search
 * @returns a JSX element.
 */
export const FacultyAutoSuggest = (props) => {
  const {
    label,
    isValid,
    maxResults = 10,
    minChars = 2,
    debouncePeriod = 200,
    onChange,
    value,
    valueObject,
    ...passedParams
  } = props;
  const { findByQuery } = useFacultyApi();
  const { t } = useTranslation();
  //const { setAlert, clearAlert } = useAlertContext();
  const [partialText, setPartialText] = React.useState("");
  let options = {},
    facultyOptions = {},
    config = { searchQuery: "" };

  /**
   * Perform the search for faculty suggestions.
   *
   * @private
   * React.useCallback (which is what is used in the 'useAsync' hook) does not support arguments.
   * I'm setting a property on the config object to pass the searchText by reference to the target method.
   */
  const loadFacultyOptions = () => {
    let promisedSearch;
    if (config.searchQuery !== "") {
      promisedSearch = findByQuery(config.searchQuery, maxResults);
    } else {
      promisedSearch = Promise.resolve();
    }
    return promisedSearch;
  };

  // Wrap the HTTP request for faculty suggestions in the 'useAsync' hook
  const {
    execute: executeSearch,
    status,
    value: response,
    error,
    reset,
  } = useAsync(loadFacultyOptions, false);

  const searchForSuggestions = debounce(executeSearch, debouncePeriod);

  /**
   * Handles updated search term text.
   * @private
   * @param {object} event the event object.
   * @param {string} searchText the search query.
   */
  const onChangeSearchQuery = (event, searchText) => {
    if (event?.type === "change") {
      if (searchText.trim().length >= minChars) {
        // set property on config object to pass 'searchText' by ref to target method.
        config.searchQuery = searchText.trim();
        searchForSuggestions();
      } else {
        // Force a re-render to update the options in the popup
        reset(setPartialText(searchText.trim()));
      }
    }
  };

  // Set text based on current state
  switch (status) {
    case "success":
      options = response.data.values;
      if (Array.isArray(options) && options.length > 0) {
        facultyOptions = toMap(options); // Convert array to hashmap

        if (options.length > 1) {
          facultyOptions["STATUS"] = t("common.autosuggest.topResults", {
            numResults: options.length,
          });
        }
      } else {
        facultyOptions["STATUS"] = t("common.autosuggest.noResults");
      }
      break;
    case "error":
      if (error.response.data.message) {
        /*setAlert(
          "error",
          t(
            `common:apiErrorDefaultMessages.${error.response.data.status}.${error.response.data.code}`,
            error.response.data.message
          )
        );*/
      }
      facultyOptions["STATUS"] = t("common.autosuggest.noResults");
      break;
    case "pending":
      facultyOptions["STATUS"] = t("common.autosuggest.searching");
      break;
    case "idle":
    default:
      if (value === null || partialText === "") {
        facultyOptions["STATUS"] = t("common.autosuggest.queryTooShort", {
          minChars: minChars,
        });
      }
  }

  /**
   * Returns a formatted faculty autocomplete option.
   * @private
   * @param {object} faculty the faculty search result.
   * @returns a JSX element.
   */
  const formatOption = (faculty) => {
    let { value: values, /*matchesOn, */ displayText } = faculty;
    // Output
    return (
      <span>
        {displayText}
        {(!!values.rank || !!values.departmentName) && (
          <>
            <br />
            {!!!values.departmentName ? (
              <>{values.rank}</>
            ) : (
              <>
                {values.rank}, {values.departmentName}
              </>
            )}
          </>
        )}
      </span>
    );
  };

  return (
    <Autocomplete
      {...passedParams}
      value={value}
      freeSolo
      disableClearable
      openOnFocus={value === null}
      filterOptions={(optns, state) => optns}
      onChange={(event, val) => {
        // NOTE: Passing the whole faculty search result object (vs just the key)
        onChange(event, facultyOptions[val]);
      }}
      onInputChange={onChangeSearchQuery}
      options={Object.keys(facultyOptions)}
      getOptionDisabled={(option) => {
        return option === "STATUS";
      }}
      renderOption={(option) => {
        return option === "STATUS" ? (
          <span>{facultyOptions[option]}</span>
        ) : (
          formatOption(facultyOptions[option])
        );
      }}
      getOptionLabel={(option) => {
        return valueObject?.universityId === option
          ? valueObject?.fullName
          : facultyOptions[option]?.value?.fullName || "";
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          error={!!isValid}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {status === "pending" ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

/**
 * Convert array of faculty search results to map of faculty search results.
 * @private
 * @param {array} sourceArray the array to convert.
 * @returns map of faculty search results.
 */
const toMap = (sourceArray) => {
  let map = {};
  sourceArray.forEach((entry) => {
    map[entry.value["universityId"]] = entry;
  });
  return map;
};
