// src/services/facultyService.js
// Faculty service for the "faculty/" endpoint, handling faculty state management and API errors.
// Exports a useFaculty custom hook for accessing faculty data, such as displayName and permissions.

//import React from "react";
import axios from "axios";

/**
 * A React hook that allows functional components to access the Faculty API.
 */
export const useFacultyApi = () => {
  /**
   * Returns List of Faculties for the input value entered.
   *
   * @param {string} searchTerm the search string.
   * @param {number} numResults the max number of resulst to return.
   * @returns a promise to find search results.
   */
  const findByQuery = (searchTerm, numResults) => {
    const request = {
      method: "get",
      url: `/faculty/autocomplete?value=${searchTerm}&size=${numResults}`,
    };
    return axios(request);
  };

  return { findByQuery };
};
