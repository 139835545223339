import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";
import { Grid, Link, TextField, Typography } from "@material-ui/core";
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useStepStyles } from "./StepStyles";
import { ResultingDegreeEnums, stepName } from "../constants";
import {
  getYearsDecending,
  addObjectToCollection,
  removeObjectFromCollection,
  isString,
} from "../utils/utils";
import { FacultyAutoSuggest } from "./trainingAtStanford/FacultyAutoSuggest";

const degreeYears = getYearsDecending(21);

const maxFaculty = 2;
const maxResultingDegrees = 5;

const isFullDegree = (degree) => {
  return (
    isString(degree) &&
    degree.length > 0 &&
    !["IN_TRAINING", "NONE"].includes(degree)
  );
};

export const TrainingAtStanfordStep = ({ traineeData, callbacks }) => {
  const classes = useStepStyles();
  const { t } = useTranslation();
  const { register, control, errors } = useFormContext();

  const [faculty, setFaculty] = React.useState(
    Array.isArray(traineeData.faculty)
      ? traineeData.faculty.slice(0, maxFaculty)
      : [{ universityId: null }]
  );
  const [resultingDegrees, setResultingDegrees] = React.useState(
    Array.isArray(traineeData.resultingDegrees)
      ? traineeData.resultingDegrees.slice(0, maxResultingDegrees)
      : [{ degree: null }]
  );

  const [startDate, setStartDate] = React.useState(
    traineeData.trainingStartDate
      ? new Date(traineeData.trainingStartDate)
      : null
  );
  const [endDate, setEndDate] = React.useState(
    traineeData.trainingEndDate ? new Date(traineeData.trainingEndDate) : null
  );
  const startDateMin = new Date(null);
  const startDateMax = endDate ? endDate : new Date();
  const endDateMin = startDate ? startDate : new Date(null);

  /**
   * Processes form data before submitting.
   *
   * @param {object} formData the values from the form
   * @returns {object} the processed form data
   */
  callbacks.beforeSubmit = (formData) => {
    //console.log("Training at Stanford - beforeSubmit");
    const degrees = formData.resultingDegrees.map((entry) => {
      if (
        entry.degree &&
        !Object.keys(ResultingDegreeEnums).includes(entry.degree)
      ) {
        return {
          ...entry,
          degree: "OTHER_D",
        };
      } else {
        delete entry.equivalentDoctoralDegree;
        return entry;
      }
    });
    return {
      stepName: stepName.ABOUT_TRAINING_AT_STANFORD,
      trainingStartDate: formData.trainingStartdate,
      trainingEndDate: formData.trainingEnddate,
      researchTopic: formData.researchTopic,
      resultingDegrees: degrees,
      faculty: formData.faculty,
    };
  };

  /**
   * Returns a function that updates a faculty entry.
   * @private
   * @param {number} idx the index of the faculty to update.
   */
  const updateFaculty = (idx) => ([event, suggestedFaculty]) => {
    if (suggestedFaculty?.value?.universityId) {
      const newFacultyCollection = faculty.map((facultyEntry, fidx) => {
        let entry;
        if (idx === fidx) {
          entry = {
            ...facultyEntry,
            universityId: suggestedFaculty.value.universityId,
            fullName: suggestedFaculty.value.fullName,
          };
        } else {
          entry = facultyEntry;
        }
        return entry;
      });
      setFaculty(newFacultyCollection);
    }
    return suggestedFaculty?.value?.universityId;
  };

  const addFaculty = () =>
    addObjectToCollection({ fullname: "" }, faculty, setFaculty, async () => {
      control.register(`faculty[${faculty.length}].universityId`, {
        required: true,
      });
      await control.triggerValidation();
    });

  const removeFaculty = (idx) =>
    removeObjectFromCollection(idx, setFaculty, async () => {
      control.unregister([
        `faculty[${idx}].fullName`,
        `faculty[${idx}].universityId`,
      ]);
      await control.triggerValidation();
    });

  //-------------------------------------

  /**
   * Returns degree options.
   * @private
   * @param {boolean} filterNonDegreeOptions flag to return only full degrees.
   * @returns {Array<string>} an array of degrees.
   */
  const getDegreeOptions = (filterNonDegreeOptions = false) =>
    Object.keys(ResultingDegreeEnums).filter((degree) => {
      return filterNonDegreeOptions ? isFullDegree(degree) : true;
    });

  /**
   * Returns a function that provides a label for a degree.
   * @private
   * @param {string} altLabel the alternate label to display if one is not avail.
   * @returns {string} the label.
   */
  const getDegreeLabel = (altLabel) => (option) => {
    let label = "";
    if (Object.keys(ResultingDegreeEnums).includes(option)) {
      label = t(ResultingDegreeEnums[option]);
    } else {
      if (option === "OTHER_D") {
        label = altLabel;
      } else {
        label = option;
      }
    }
    return label;
  };

  /**
   * Returns a callback function that updates a property of a resulting degree (referenced by index).
   * The retured callback returns the passed property value.
   * NOTE: React-hook-form onChange callback sends the "event and value" params as an array.
   * @private
   * @param {number} idx the index of the object to update.
   * @param {string} keyName the name of the property to update.
   */
  const updateResultingDegree = (idx, keyName) => (...args) => {
    // Support two method signatures:
    // - React-hook-form callback: ([event, value])
    // - Material UI callback: (event, value)
    let [event, value] = Array.isArray(args[0]) ? args[0] : args;
    value = value || event.target.value;
    const degreeKeys = Object.keys(ResultingDegreeEnums);

    // Correct the input
    // - Work around for MUI autocomplete bug:
    // - The embedded TextField will "sometimes" emit a blur event that contains the label vs the underlying option (re: key/value pairs).
    // - This resets to the expected option
    if (value && keyName === "degree" && !degreeKeys.includes(value)) {
      let foundKey = degreeKeys.find(
        (k) => t(ResultingDegreeEnums[k]) === value
      );
      value = foundKey ? foundKey : value;
    }

    const newResultingDegree = resultingDegrees.map((degreeEntry, fidx) => {
      let entry;
      if (idx === fidx) {
        if (keyName === "degree" && !getDegreeOptions().includes(value)) {
          entry = {
            ...degreeEntry,
            degree: "OTHER_D",
            equivalentDoctoralDegree: value,
          };
        } else {
          entry = {
            ...degreeEntry,
            [keyName]: value,
          };
        }
      } else {
        entry = degreeEntry;
      }
      return entry;
    });
    setResultingDegrees(newResultingDegree);
    return value;
  };

  const addResultingDegree = () =>
    addObjectToCollection(
      { degree: "" },
      resultingDegrees,
      setResultingDegrees,
      async () => {
        control.register(`resultingDegrees[${faculty.length}].degree`, {
          required: true,
        });
        await control.triggerValidation();
      }
    );

  const removeResultingDegree = (idx) =>
    removeObjectFromCollection(idx, setResultingDegrees, async () => {
      control.unregister([
        `resultingDegrees[${idx}].degree`,
        `resultingDegrees[${idx}].year`,
      ]);
      await control.triggerValidation();
    });

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="flex-start"
      direction="column"
      classes={{ container: classes.containerWrapper }}
      spacing={2}
    >
      <input
        type="hidden"
        name="page"
        value="trainingAtStanfordStep"
        ref={register}
      />
      <Grid item classes={{ item: classes.stepItem }}>
        <Typography variant={"h2"} classes={{ root: classes.stepTitle }}>
          {t("steps.trainingAtStanford.h2Title")}
        </Typography>
      </Grid>
      <Grid item classes={{ item: classes.stepItem }}>
        <Typography variant={"h4"} classes={{ root: classes.stepSubtitle }}>
          {t("steps.trainingAtStanford.faculty")}
        </Typography>
      </Grid>
      <Grid item classes={{ item: classes.stepItem }}>
        <Grid container direction="column" alignItems="flex-start" spacing={2}>
          {faculty.map((field, idx) => {
            return (
              <React.Fragment key={field.universityId || `field-${idx}`}>
                <Grid item classes={{ item: classes.stepItem }}>
                  <input
                    type="hidden"
                    name={`faculty[${idx}].fullName`}
                    value={field.fullName}
                    ref={register}
                  />
                  <Controller
                    name={`faculty[${idx}].universityId`}
                    control={control}
                    defaultValue={field.universityId || null}
                    rules={{
                      required: true,
                    }}
                    onChange={updateFaculty(idx)}
                    as={
                      <FacultyAutoSuggest
                        label={t("steps.trainingAtStanford.facultyName")}
                        isValid={!!errors?.faculty?.[idx]?.universityId}
                        valueObject={field}
                      />
                    }
                  />
                </Grid>
                {idx === faculty.length - 1 && (
                  <Grid item classes={{ item: classes.stepItem }}>
                    <Grid container direction="row" justify="flex-end">
                      <>
                        {faculty.length < maxFaculty && (
                          <Link
                            classes={{ root: classes.degreeLink }}
                            component="button"
                            type="button"
                            onClick={addFaculty}
                          >
                            <AddIcon /> {t("common.add")}{" "}
                            {t("steps.trainingAtStanford.faculty")}
                          </Link>
                        )}
                        {faculty.length > 1 && (
                          <Link
                            classes={{ root: classes.degreeLink }}
                            component="button"
                            type="button"
                            onClick={removeFaculty(idx)}
                          >
                            <RemoveIcon /> {t("common.remove")}{" "}
                            {t("steps.trainingAtStanford.faculty")}
                          </Link>
                        )}
                      </>
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            );
          })}
        </Grid>
      </Grid>
      <Grid item classes={{ item: classes.stepItem }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Controller
            as={
              <KeyboardDatePicker
                views={["year", "month"]}
                minDate={startDateMin}
                maxDate={startDateMax}
                value={startDate}
                onChange={setStartDate}
                inputVariant="outlined"
                label={t("steps.trainingAtStanford.trainingStartDate")}
                name="trainingStartdate"
                inputProps={{ autoComplete: "off" }}
                clearable
                margin="normal"
                required
                error={!!errors.trainingStartdate}
              />
            }
            control={control}
            defaultValue={startDate}
            name="trainingStartdate"
            placeholder={t("steps.trainingAtStanford.monthYear")}
            rules={{
              required: true,
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item classes={{ item: classes.stepItem }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Controller
            as={
              <KeyboardDatePicker
                views={["year", "month"]}
                minDate={endDateMin}
                value={endDate}
                onChange={setEndDate}
                inputVariant="outlined"
                label={t("steps.trainingAtStanford.trainingEndDate")}
                name="trainingEnddate"
                inputProps={{ autoComplete: "off" }}
                clearable
                margin="normal"
                required
                error={!!errors.trainingEnddate}
              />
            }
            control={control}
            defaultValue={endDate}
            name="trainingEnddate"
            placeholder={t("steps.trainingAtStanford.monthYear")}
            rules={{
              required: true,
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item classes={{ item: classes.stepItem }}>
        <Typography variant={"h4"} classes={{ root: classes.stepSubtitle }}>
          {t("steps.trainingAtStanford.resultingDegree")}
        </Typography>
      </Grid>
      <Grid item classes={{ item: classes.stepItem }}>
        <Grid container direction="column" alignItems="flex-start" spacing={2}>
          {resultingDegrees.map((field, idx) => {
            let degree = field.degree || field.equivalentDoctoralDegree;
            let key =
              field.degree && field.degree !== "OTHER_D"
                ? field.degree
                : `equivalent-${idx}`;
            return (
              <React.Fragment key={`${key}-${idx}`}>
                <Grid item classes={{ item: classes.stepItem }}>
                  <Controller
                    name={`resultingDegrees[${idx}].degree`}
                    control={control}
                    defaultValue={field.degree || null}
                    rules={{
                      required: true,
                    }}
                    onChange={updateResultingDegree(idx, "degree")}
                    as={
                      <Autocomplete
                        freeSolo
                        options={getDegreeOptions(idx > 0)} // Provide all options on first input
                        getOptionLabel={getDegreeLabel(
                          field.equivalentDoctoralDegree
                        )}
                        selectOnFocus
                        clearOnBlur
                        autoSelect
                        handleHomeEndKeys
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            error={!!errors?.resultingDegrees?.[idx]?.degree}
                            label={t("common.degree")}
                            variant="outlined"
                          />
                        )}
                      />
                    }
                  />
                  <input
                    type="hidden"
                    name={`resultingDegrees[${idx}].equivalentDoctoralDegree`}
                    value={field.equivalentDoctoralDegree}
                    ref={register}
                  />
                </Grid>
                {isFullDegree(degree) && (
                  <Grid item classes={{ item: classes.stepItem }}>
                    <TextField
                      select
                      name={`resultingDegrees[${idx}].year`}
                      label={t("common.year")}
                      defaultValue={field.year || ""}
                      onChange={updateResultingDegree(idx, "year")}
                      SelectProps={{
                        native: true,
                      }}
                      inputRef={register({
                        validate: (value) => !!value.trim(),
                      })}
                      required
                      error={!!errors?.resultingDegrees?.[idx]?.year}
                      variant="outlined"
                      margin="normal"
                    >
                      <option
                        disabled
                        hidden
                        style={{ display: "none" }}
                        value=""
                      ></option>
                      {degreeYears.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                )}
                <Grid item classes={{ item: classes.stepItem }}>
                  <Grid container direction="row" justify="flex-end">
                    <>
                      {resultingDegrees.length < maxResultingDegrees &&
                        idx === resultingDegrees.length - 1 && (
                          <Link
                            classes={{ root: classes.degreeLink }}
                            component="button"
                            type="button"
                            onClick={addResultingDegree}
                          >
                            <AddIcon /> {t("common.add")} {t("common.degree")}
                          </Link>
                        )}
                      {resultingDegrees.length > 1 &&
                        idx === resultingDegrees.length - 1 && (
                          <Link
                            classes={{ root: classes.degreeLink }}
                            component="button"
                            type="button"
                            onClick={removeResultingDegree(idx)}
                          >
                            <RemoveIcon /> {t("common.remove")}{" "}
                            {t("common.degree")}
                          </Link>
                        )}
                    </>
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </Grid>
      <Grid item classes={{ item: classes.stepItem }}>
        <TextField
          name="researchTopic"
          label={t("steps.trainingAtStanford.researchTopic")}
          defaultValue={traineeData.researchTopic}
          inputRef={register({
            validate: (value) => !!value.trim(),
          })}
          required
          error={!!errors.researchTopic}
          variant="outlined"
          inputProps={{ maxLength: 150 }}
        />
      </Grid>
    </Grid>
  );
};
