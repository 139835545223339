import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { Grid, TextField, Typography } from "@material-ui/core";
import { useStepStyles } from "./StepStyles";
import { createCollectionUpdateMethod } from "../utils/utils";
import {
  EmploymentPositionTypes,
  EmploymentActivityTypes,
  EmploymentWorkforceSectorTypes,
  stepName,
} from "../constants";

const maxEmployment = 2;

export const YourEmploymentStep = ({ traineeData, callbacks }) => {
  const classes = useStepStyles();
  const { t } = useTranslation();
  const { register, errors } = useFormContext();

  const [employments, setEmployments] = React.useState(
    Array.isArray(traineeData.employments)
      ? traineeData.employments.slice(0, maxEmployment)
      : [{ workforceSector: null, activity: null }, { workforceSector: null, activity: null }]
  );

  /**
   * Processes form data before submitting.
   *
   * @param {object} formData the values from the form
   * @returns {object} the processed form data
   */
  callbacks.beforeSubmit = (formData) => {
    //console.log("YourEmploymentStep - beforeSubmit");
    const jobs = formData.employments.map((job) => ({
      ...(job.activity && { activity: job.activity }),
      ...(job.workforceSector && { workforceSector: job.workforceSector }),
      ...(job.department && { department: job.department }),
      ...(job.institution && { institution: job.institution }),
      ...(job.position && { position: job.position }),
      positionType: job.positionType,
    }));
    return {
      stepName: stepName.ABOUT_YOUR_EMPLOYMENT,
      employments: jobs,
    };
  };

  const updateEmployment = (idx, keyName) =>
    createCollectionUpdateMethod(idx, keyName, employments, setEmployments);

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="flex-start"
      direction="column"
      classes={{ container: classes.containerWrapper }}
      spacing={2}
    >
      <input
        type="hidden"
        name="page"
        value="yourEmploymentStep"
        ref={register}
      />
      <Grid item classes={{ item: classes.stepItem }}>
        <Typography variant={"h2"} classes={{ root: classes.stepTitle }}>
          {t("steps.yourEmployment.h2Title")}
        </Typography>
      </Grid>
      {employments.map((field, idx) => {
        const positionIsRequired =
          !!field?.department?.trim() ||
          !!field?.institution?.trim() ||
          !!field?.workforceSector?.trim() ||
          !!field?.activity?.trim();

        return (
          <React.Fragment key={`${field.positionType}-${idx}`}>
            <input
              type="hidden"
              name={`employments[${idx}].positionType`}
              value={
                idx === EmploymentPositionTypes.CURRENT_POSITION
                  ? "CURRENT_POSITION"
                  : "INITIAL_POSITION"
              }
              ref={register}
            />
            <Grid item classes={{ item: classes.stepItem }}>
              <Typography
                variant={"h4"}
                classes={{ root: classes.stepSubtitle }}
              >
                {idx === 0
                  ? t("steps.yourEmployment.initialPosition")
                  : t("steps.yourEmployment.currentPosition")}
              </Typography>
            </Grid>
            <Grid item classes={{ item: classes.stepItem }}>
              <TextField
                name={`employments[${idx}].position`}
                label={t("steps.yourEmployment.position")}
                defaultValue={field.position || null}
                inputRef={register({
                  validate: (value) => {
                    return positionIsRequired ? !!value.trim() : true;
                  },
                })}
                required={positionIsRequired}
                error={
                  positionIsRequired
                    ? !!errors?.employments?.[idx]?.position
                    : false
                }
                variant="outlined"
                inputProps={{ maxLength: 50 }}
                autoComplete="off"
                onChange={updateEmployment(idx, "position")}
                onBlur={updateEmployment(idx, "position")}
              />
            </Grid>
            <Grid item classes={{ item: classes.stepItem }}>
              <TextField
                name={`employments[${idx}].department`}
                label={t("steps.yourEmployment.department")}
                defaultValue={field.department || null}
                inputRef={register()}
                error={!!errors?.employments?.[idx]?.department}
                variant="outlined"
                inputProps={{ maxLength: 50 }}
                autoComplete="off"
                onChange={updateEmployment(idx, "department")}
                onBlur={updateEmployment(idx, "department")}
              />
            </Grid>
            <Grid item classes={{ item: classes.stepItem }}>
              <TextField
                name={`employments[${idx}].institution`}
                label={t("steps.yourEmployment.institution")}
                defaultValue={field.institution || null}
                inputRef={register()}
                error={!!errors?.employments?.[idx]?.institution}
                variant="outlined"
                inputProps={{ maxLength: 50 }}
                autoComplete="off"
                onChange={updateEmployment(idx, "institution")}
                onBlur={updateEmployment(idx, "institution")}
              />
            </Grid>
            <Grid item classes={{ item: classes.stepItem }}>
              <TextField
                select
                name={`employments[${idx}].workforceSector`}
                label={t("steps.yourEmployment.workforceSector")}
                defaultValue={field.workforceSector || ""}
                onChange={updateEmployment(idx, "workforceSector")}
                SelectProps={{
                  native: true,
                }}
                inputRef={register()}
                error={!!errors?.employments?.[idx]?.workforceSector}
                variant="outlined"
                margin="normal"
              >
                <option
                  disabled
                  hidden
                  style={{ display: "none" }}
                  value=""
                ></option>
                {Object.keys(EmploymentWorkforceSectorTypes).map((option) => (
                  <option key={option} value={option}>
                    {t(EmploymentWorkforceSectorTypes[option])}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item classes={{ item: classes.stepItem }}>
              <TextField
                select
                name={`employments[${idx}].activity`}
                label={t("steps.yourEmployment.activity")}
                defaultValue={field.activity || ""}
                onChange={updateEmployment(idx, "activity")}
                SelectProps={{
                  native: true,
                }}
                inputRef={register()}
                error={!!errors?.employments?.[idx]?.activity}
                variant="outlined"
                margin="normal"
              >
                <option
                  disabled
                  hidden
                  style={{ display: "none" }}
                  value=""
                ></option>
                {Object.keys(EmploymentActivityTypes).map((option) => (
                  <option key={option} value={option}>
                    {t(EmploymentActivityTypes[option])}
                  </option>
                ))}
              </TextField>
            </Grid>
          </React.Fragment>
        );
      })}
    </Grid>
  );
};
