import { Grid, Link, TextField, Typography } from "@material-ui/core";
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { Controller, useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { GrantRoles, stepName } from "../constants";
import {
  //createCollectionUpdateMethod,
  addObjectToCollection,
  getYearsDecending,
  removeObjectFromCollection,
} from "../utils/utils";
import { useStepStyles } from "./StepStyles";

const maxSubsequentGrants = 5;
const grantYears = getYearsDecending(20);

export const SubsequentGrantsStep = ({ traineeData, callbacks }) => {
  const classes = useStepStyles();
  const { t } = useTranslation();
  const { register, control, errors } = useFormContext();
  const { setValue } = useForm();

  const [subsequentGrants, setSubsequentGrants] = React.useState(
    Array.isArray(traineeData.subsequentGrants)
      ? traineeData.subsequentGrants.slice(0, maxSubsequentGrants)
      : [{ type: "" }]
  );

  /**
   * Processes form data before submitting.
   *
   * @param {object} formData the values from the form
   * @returns {object} the processed form data
   */
  callbacks.beforeSubmit = (formData) => {
    //console.log("SubsequentGrantsStep - beforeSubmit");
    const grants = formData.subsequentGrants.map((entry) => {
      if (entry.role && !Object.keys(GrantRoles).includes(entry.role)) {
        return {
          ...entry,
          role: "OTHER_D",
        };
      } else {
        delete entry.equivalentRole;
        return entry;
      }
    });
    return {
      stepName: stepName.ABOUT_SUBSEQUENT_GRANTS,
      subsequentGrants: grants,
    };
  };

  /**
   * Returns a function that provides a label for a grant role.
   * @private
   * @param {string} altLabel the alternate label to display if one is not avail.
   * @returns {string} the label.
   */
  const getGrantRoleLabel = (altLabel) => (option) => {
    let label = "";
    if (Object.keys(GrantRoles).includes(option)) {
      label = t(GrantRoles[option]);
    } else {
      if (option === "OTHER_D") {
        label = altLabel;
      } else {
        label = option;
      }
    }
    return label;
  };

  /**
   * Returns a callback function that updates a property of a Subsequent Grant (referenced by index).
   * The retured callback returns the passed property value.
   * NOTE: React-hook-form onChange callback sends the "event and value" params as an array.
   * @private
   * @param {number} idx the index of the object to update.
   * @param {string} keyName the name of the property to update.
   */
  const updateSubsequentGrants =
    (idx, keyName) =>
    (...args) => {
      // Support two method signatures:
      // - React-hook-form callback: ([event, value])
      // - Material UI callback: (event, value)
      let [event, value] = Array.isArray(args[0]) ? args[0] : args;
      value = value || event.target.value;
      const grantKeys = Object.keys(GrantRoles);

      // Correct the input
      // - Work around for MUI autocomplete bug:
      // - The embedded TextField will "sometimes" emit a blur event that contains the label vs the underlying option (re: key/value pairs).
      // - This resets to the expected option
      if (value && keyName === "role" && !grantKeys.includes(value)) {
        let foundKey = grantKeys.find((k) => t(GrantRoles[k]) === value);
        value = foundKey ? foundKey : value;
      }

      if (value && keyName === "type") {
        const inputName = `subsequentGrants[${idx}].type`;
        setValue(inputName, value.toUpperCase());
      }

      const newSubsequentGrant = subsequentGrants.map((grantEntry, fidx) => {
        let entry;
        if (idx === fidx) {
          if (keyName === "role" && !grantKeys.includes(value)) {
            entry = {
              ...grantEntry,
              role: "OTHER_D",
              equivalentRole: value,
            };
          } else {
            entry = {
              ...grantEntry,
              [keyName]: value,
            };
          }
        } else {
          entry = grantEntry;
        }
        return entry;
      });
      setSubsequentGrants(newSubsequentGrant);
      return value;
    };

  const addSubsequentGrant = () =>
    addObjectToCollection(
      { type: null },
      subsequentGrants,
      setSubsequentGrants,
      async () => {
        control.register(`subsequentGrants[${subsequentGrants.length}].type`);
        await control.triggerValidation();
      }
    );

  const removeSubsequentGrant = (idx) =>
    removeObjectFromCollection(idx, setSubsequentGrants, async () => {
      control.unregister([
        `subsequentGrants[${idx}].type`,
        `subsequentGrants[${idx}].role`,
        `subsequentGrants[${idx}].year`,
      ]);
      await control.triggerValidation();
    });

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="flex-start"
      direction="column"
      classes={{ container: classes.containerWrapper }}
      spacing={2}
    >
      <input
        type="hidden"
        name="page"
        value="subsequentGrantsStep"
        ref={register}
      />
      <Grid item classes={{ item: classes.stepItem }}>
        <Typography variant={"h2"} classes={{ root: classes.stepTitle }}>
          {t("steps.subsequentGrants.h2Title")}
        </Typography>
      </Grid>

      <Grid item classes={{ item: classes.stepItem }}>
        <Grid container direction="column" alignItems="flex-start" spacing={2}>
          <Grid item classes={{ item: classes.stepItem }}>
            <Typography variant={"h4"} classes={{ root: classes.stepSubtitle }}>
              {t("steps.subsequentGrants.grants")}
            </Typography>
          </Grid>
          {subsequentGrants.map((field, idx) => {
            var roleAndYearIsRequired = !!subsequentGrants[idx]?.type?.trim();
            return (
              <React.Fragment key={`${field}-${idx}`}>
                <Grid item classes={{ item: classes.stepItem }}>
                  <Controller
                    name={`subsequentGrants[${idx}].type`}
                    control={control}
                    defaultValue={field.type || ""}
                    rules={{
                      required: false,
                      validate: (value) => {
                        return value.length > 0;
                      },
                    }}
                    onChange={updateSubsequentGrants(idx, "type")}
                    onBlur={updateSubsequentGrants(idx, "type")}
                    as={
                      <TextField
                        error={!!errors?.subsequentGrants?.[idx]?.type}
                        label={t("steps.trainingSupport.support")}
                        variant="outlined"
                        helperText={t("steps.trainingSupport.supportHelpText")}
                        inputProps={{ maxLength: 30 }}
                        autoComplete="off"
                      />
                    }
                  />
                  {/* <TextField
                    name={`subsequentGrants[${idx}].type`}
                    label={t("steps.subsequentGrants.grantType")}
                    value={field?.type || null}
                    inputRef={register()}
                    error={!!errors?.subsequentGrants?.[idx]?.type}
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                    placeholder="__ ___"
                    autoComplete="off"
                    onChange={updateSubsequentGrants(idx, "type")}
                    onBlur={updateSubsequentGrants(idx, "type")}
                  /> */}
                </Grid>
                <Grid item classes={{ item: classes.stepItem }}>
                  <Controller
                    name={`subsequentGrants[${idx}].role`}
                    control={control}
                    defaultValue={field.role || null}
                    rules={{
                      required: roleAndYearIsRequired,
                      validate: (value) => {
                        return roleAndYearIsRequired ? !!value.trim() : true;
                      },
                    }}
                    onChange={updateSubsequentGrants(idx, "role")}
                    //onInputChange={updateSubsequentGrants(idx, "role")}
                    as={
                      <Autocomplete
                        freeSolo
                        options={Object.keys(GrantRoles)}
                        getOptionLabel={getGrantRoleLabel(field.equivalentRole)}
                        selectOnFocus
                        clearOnBlur
                        autoSelect
                        handleHomeEndKeys
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required={roleAndYearIsRequired}
                            error={
                              roleAndYearIsRequired
                                ? !!errors?.subsequentGrants?.[idx]?.role
                                : false
                            }
                            label={t("steps.subsequentGrants.grantRole")}
                            variant="outlined"
                          />
                        )}
                      />
                    }
                  />
                  <input
                    type="hidden"
                    name={`subsequentGrants[${idx}].equivalentRole`}
                    value={field.equivalentRole}
                    ref={register}
                  />
                </Grid>
                <Grid item classes={{ item: classes.stepItem }}>
                  <TextField
                    select
                    name={`subsequentGrants[${idx}].year`}
                    label={t("steps.subsequentGrants.grantYear")}
                    defaultValue={field.year || ""}
                    onChange={updateSubsequentGrants(idx, "year")}
                    SelectProps={{
                      native: true,
                    }}
                    inputRef={register({
                      validate: (value) => {
                        return roleAndYearIsRequired ? !!value.trim() : true;
                      },
                    })}
                    required={roleAndYearIsRequired}
                    error={
                      roleAndYearIsRequired
                        ? !!errors?.subsequentGrants?.[idx]?.year
                        : false
                    }
                    variant="outlined"
                    margin="normal"
                  >
                    <option
                      disabled
                      hidden
                      style={{ display: "none" }}
                      value=""
                    ></option>
                    {grantYears.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item classes={{ item: classes.stepItem }}>
                  <Grid container direction="row" justify="flex-end">
                    {subsequentGrants.length < maxSubsequentGrants &&
                      idx === subsequentGrants.length - 1 && (
                        <Link
                          classes={{ root: classes.degreeLink }}
                          component="button"
                          type="button"
                          onClick={addSubsequentGrant}
                        >
                          <AddIcon /> {t("common.add")}{" "}
                          {t("steps.subsequentGrants.grant")}
                        </Link>
                      )}
                    {subsequentGrants.length > 1 &&
                      idx === subsequentGrants.length - 1 && (
                        <Link
                          classes={{ root: classes.degreeLink }}
                          component="button"
                          type="button"
                          onClick={removeSubsequentGrant(idx)}
                        >
                          <RemoveIcon /> {t("common.remove")}{" "}
                          {t("steps.subsequentGrants.grant")}
                        </Link>
                      )}
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};
