import { makeStyles } from "@material-ui/core/styles";

export const usePortalStyles = makeStyles((theme) => ({
  parentWrapper: { padding: "0", height: "100%" },
  itemWrapper: { padding: "20px 0", width: "100%" },
  stepperWrapper: { width: "100%" },
  formContentWrapper: {
    width: "100%",
    padding: "20px 15px 0px 15px",
  },
  buttonsWrapper: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  headerTitleRoot: {
    fontSize: "34px",
    fontWeight: 500,
    textAlign: "center",
  },
  headerSubTitle1: {
    fontSize: "18px",
    fontWeight: 600,
    textAlign: "center",
    color: "#333333",
  },
  headerSubTitle2: {
    fontSize: "18px",
    fontWeight: 400,
    textAlign: "center",
    color: "#333333",
  },
  containerWrapper: {
    background: "#f0f4f5",
    padding: "0",
    margin: "0",
    width: "100%",
    height: "100%",
  },
  checkIcon: { color: "#009779" },
  backButton: {
    marginRight: theme.spacing(1),
  },
  nextButton: {
    "& .MuiCircularProgress-root": {
      marginRight: "10px",
      color: "#ffffff",
    },
  },
  confirmDialog: {
    "& .MuiDialogContent-root": {
      fontSize: "16px",
    },
  },
}));
