import { makeStyles } from "@material-ui/core/styles";

export const useStepStyles = makeStyles({
  containerWrapper: {
    width: "100%",
    margin: 0,
    "& .MuiFormControl-root": {
      width: "100%",
      margin: 0,
    },
    "& .MuiOutlinedInput-root": {
      background: "#fff",
    },
  },
  stepTitle: {
    fontSize: "24px",
    fontWeight: 400,
    textAlign: "left",
  },
  stepSubtitle: {
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "left",
    color: "#333333",
  },
  stepItem: {
    padding: 0,
    width: "100%",
  },
  degreeLink: {
    verticalAlign: "middle",
    display: "inline-flex",
    color: "#009abb",
    fontSize: "16px",
    fontWeight: 600,
    textTransform: "uppercase",
    backgroundColor: "transparent",
    border: "none",
    "&:hover": { textDecoration: "none", cursor: "pointer", color: "#009abb" },
    "& .MuiSvgIcon-root": {
      height: "0.6em",
      width: "0.6em",
      marginTop: "0.2em",
      marginRight: "0.05em",
    },
    "& + $degreeLink": {
      marginLeft: "13px",
    },
  },
});
