import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid, Link, TextField, Typography } from "@material-ui/core";
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons";
import { useStepStyles } from "./StepStyles";
import { TerminalDegreeEnums, stepName } from "../constants";
import {
  getYearsDecending,
  createCollectionUpdateMethod,
  addObjectToCollection,
  removeObjectFromCollection,
} from "../utils/utils";

const degreeYears = getYearsDecending(99);

const maxTerminalDegrees = 2;

const isFullDegree = (degree) => ["MD", "PHD"].includes(degree);

// NOTE: traineeData gets set every time the form is submitted (next button is clicked)
export const AboutYouStep = ({ traineeData, callbacks }) => {
  const { register, control, errors } = useFormContext();
  const { t } = useTranslation();
  const classes = useStepStyles();

  const [terminalDegrees, setTerminalDegrees] = React.useState(
    Array.isArray(traineeData.terminalDegrees)
      ? traineeData.terminalDegrees.slice(0, maxTerminalDegrees)
      : [{ degree: null }]
  );

  /**
   * Processes form data before submitting.
   *
   * @param {object} formData the values from the form
   * @returns {object} the processed form data
   */
  callbacks.beforeSubmit = (formData) => {
    //console.log("About You - beforeSubmit");
    return {
      stepName: stepName.ABOUT_YOU,
      firstName: formData.firstName,
      middleName: formData.middleName,
      lastName: formData.lastName,
      terminalDegrees: formData.terminalDegrees,
    };
  };

  // React Hook Form onChange callback
  const updateTerminalDegree = (idx, keyName) =>
    createCollectionUpdateMethod(
      idx,
      keyName,
      terminalDegrees,
      setTerminalDegrees
    );

  const addTerminalDegree = () =>
    addObjectToCollection(
      { degree: null },
      terminalDegrees,
      setTerminalDegrees,
      async () => {
        control.register(`terminalDegrees[${terminalDegrees.length}].degree`, {
          required: true,
        });
        await control.triggerValidation();
      }
    );

  const removeTerminalDegree = (idx) =>
    removeObjectFromCollection(idx, setTerminalDegrees, async () => {
      control.unregister([
        `terminalDegrees[${idx}].degree`,
        `terminalDegrees[${idx}].year`,
      ]);
      await control.triggerValidation();
    });

  const chosenTerminalDegrees = [];
  const setChosenTerminalDegree = (degree) =>
    chosenTerminalDegrees.push(degree);

  const getAvailTerminalDegrees = () => {
    let avail = Object.keys(TerminalDegreeEnums).filter((option) => {
      if (
        ["IN_TRAINING", "NONE"].includes(option) &&
        chosenTerminalDegrees.length > 0
      ) {
        return false;
      } else {
        return !chosenTerminalDegrees.includes(option);
      }
    });
    return avail;
  };

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="flex-start"
      direction="column"
      classes={{ container: classes.containerWrapper }}
      spacing={2}
    >
      <input type="hidden" name="page" value="aboutYouStep" ref={register} />
      <Grid item classes={{ item: classes.stepItem }}>
        <Typography variant={"h2"} classes={{ root: classes.stepTitle }}>
          {t("steps.aboutYou.h2Title")}
        </Typography>
      </Grid>
      <Grid item classes={{ item: classes.stepItem }}>
        <TextField
          name="firstName"
          label={t("steps.aboutYou.firstName")}
          defaultValue={traineeData.firstName}
          inputRef={register({
            validate: (value) => !!value.trim(),
          })}
          required
          error={!!errors.firstName}
          variant="outlined"
          inputProps={{ maxLength: 100 }}
          autoComplete="given-name"
        />
      </Grid>
      <Grid item classes={{ item: classes.stepItem }}>
        <TextField
          name="middleName"
          label={t("steps.aboutYou.middleName")}
          defaultValue={traineeData.middleName}
          inputRef={register()}
          variant="outlined"
          inputProps={{ maxLength: 100 }}
          autoComplete="additional-name"
        />
      </Grid>
      <Grid item classes={{ item: classes.stepItem }}>
        <TextField
          name="lastName"
          label={t("steps.aboutYou.lastName")}
          defaultValue={traineeData.lastName}
          inputRef={register({
            validate: (value) => !!value.trim(),
          })}
          required
          error={!!errors.lastName}
          variant="outlined"
          inputProps={{ maxLength: 100 }}
          autoComplete="family-name"
        />
      </Grid>
      <Grid item classes={{ item: classes.stepItem }}>
        <Grid container direction="column" alignItems="flex-start" spacing={1}>
          <Grid item>
            <Typography variant={"h4"} classes={{ root: classes.stepSubtitle }}>
              {t("steps.aboutYou.degrees")}
            </Typography>
          </Grid>
          {terminalDegrees.map((field, idx) => {
            let availDegrees = getAvailTerminalDegrees();
            setChosenTerminalDegree(field.degree);
            return (
              <React.Fragment key={`${field.degree}-${idx}`}>
                <Grid item classes={{ item: classes.stepItem }}>
                  <TextField
                    select
                    name={`terminalDegrees[${idx}].degree`}
                    label={t("common.degree")}
                    defaultValue={field.degree || ""}
                    onChange={updateTerminalDegree(idx, "degree")}
                    SelectProps={{
                      native: true,
                    }}
                    inputRef={register({
                      validate: (value) => !!value.trim(),
                    })}
                    required
                    error={!!errors?.terminalDegrees?.[idx]?.degree}
                    variant="outlined"
                    margin="normal"
                  >
                    <option
                      disabled
                      hidden
                      style={{ display: "none" }}
                      value=""
                    ></option>
                    {availDegrees.map((option) => (
                      <option key={option} value={option}>
                        {t(TerminalDegreeEnums[option])}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                {isFullDegree(field.degree) && (
                  <Grid item classes={{ item: classes.stepItem }}>
                    <TextField
                      select
                      name={`terminalDegrees[${idx}].year`}
                      label={t("common.year")}
                      defaultValue={field.year || ""}
                      onChange={updateTerminalDegree(idx, "year")}
                      SelectProps={{
                        native: true,
                      }}
                      inputRef={register({
                        validate: (value) => !!value.trim(),
                      })}
                      required
                      error={!!errors?.terminalDegrees?.[idx]?.year}
                      variant="outlined"
                      margin="normal"
                    >
                      <option
                        disabled
                        hidden
                        style={{ display: "none" }}
                        value=""
                      ></option>
                      {degreeYears.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                )}
                <Grid item classes={{ item: classes.stepItem }}>
                  <Grid container direction="row" justify="flex-end">
                    {!["IN_TRAINING", "NONE"].some((value) =>
                      chosenTerminalDegrees.includes(value)
                    ) &&
                      terminalDegrees.length < maxTerminalDegrees &&
                      idx === terminalDegrees.length - 1 && (
                        <Link
                          classes={{ root: classes.degreeLink }}
                          component="button"
                          type="button"
                          onClick={addTerminalDegree}
                        >
                          <AddIcon /> {t("common.add")} {t("common.degree")}
                        </Link>
                      )}
                    {terminalDegrees.length > 1 &&
                      idx === terminalDegrees.length - 1 && (
                        <Link
                          classes={{ root: classes.degreeLink }}
                          component="button"
                          type="button"
                          onClick={removeTerminalDegree(idx)}
                        >
                          <RemoveIcon /> {t("common.remove")}{" "}
                          {t("common.degree")}
                        </Link>
                      )}
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </Grid>
      <Grid item></Grid>
    </Grid>
  );
};
